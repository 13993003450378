angular.module('app')
    .factory('paymentMethodService', ["$rootScope", "$log", "$http", "$q", "authenticatedStatus", "currentOrganisation", function ($rootScope, $log, $http, $q, authenticatedStatus, currentOrganisation) {
        var self = this;
        var urls = {
            list: '/payment-methods/',
            listByName: '/payment-methods/by-name/',
            create: function (organisationReference) {
                return sprintf("/payment-methods/%s", organisationReference);
            },
            updatePaymentMethod: function (organisationReference) {
                return sprintf("/payment-methods/update/%s", organisationReference);
            },
            addStaff: function (paymentMethod) {
                return sprintf("/payment-methods/%s/staff/add", paymentMethod);
            },
            removeStaff: function (paymentMethod) {
                return sprintf("/payment-methods/%s/staff/remove", paymentMethod);
            },
            removePaymentMethod: function (organisationReference, paymentMethod) {
                return sprintf("/payment-methods/%s/remove/%s", organisationReference, paymentMethod);
            },
            getPaymentMethod: function (organisationReference, paymentMethod) {
                return sprintf("/payment-methods/%s/%s", organisationReference, paymentMethod);
            }
        };

        /**
         * Process the serverside model down to be suitable for the client
         */
        function normalise(paymentMethod) {
            paymentMethod.name = paymentMethod.card ? paymentMethod.card.nickname : paymentMethod.account.accountName;
            paymentMethod.displayType = paymentMethod.card ? "Card" : "Account";
            paymentMethod.searchField = paymentMethod.name + "  " + paymentMethod.displayType + " ";

            if (paymentMethod.card) {
                paymentMethod.cardNumber = paymentMethod.card.cardNumber;
                paymentMethod.searchField += paymentMethod.card.cardNumber + " " + paymentMethod.card.expiryMonth + "/" + paymentMethod.card.expiryYear;
            }
            return paymentMethod;
        }

        function updatePaymentMethod(url, paymentMethod) {
            $log.info("Updating payment method");
            return $http.put(url, paymentMethod)
                .then(function (resp) {
                    return normalise(resp.data);
                });
        }

        var api = {
            list: function (params) {
                $log.info("Listing payment methods");
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    if (_.isUndefined(params.organisationReference)) {
                        params.organisationReference = organisationReference;
                    }
                    return $http.get(urls.list, {params: params})
                        .then(function (resp) {
                            var pagedSet = resp.data;
                            pagedSet.results = _.map(pagedSet.results, normalise);
                            return (pagedSet);
                        });
                });
            },
            listByName: function (name, page) {
                $log.info("Listing payment methods");
                var params = {
                    name: name,
                    page: page
                };
                return $http.get(urls.listByName, {params: params})
                    .then(function (resp) {
                        var pagedSet = resp.data;
                        pagedSet.results = _.map(pagedSet.results, normalise);
                        return (pagedSet);
                    });
            },
            get: function (organisationReference, paymentMethodReference) {
                $log.info("Get payment method");
                return $http.get(urls.getPaymentMethod(organisationReference, paymentMethodReference))
                    .then(function (resp) {
                        return normalise(resp.data);
                    });
            },
            create: function (paymentMethod) {
                $log.info("Creating payment method");
                return currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                    return $http.post(urls.create(organisationReference), paymentMethod)
                        .then(function (resp) {
                            return normalise(resp.data);
                        });
                });
            },
            update: function (organisationReference, paymentMethod) {
                return updatePaymentMethod(urls.updatePaymentMethod(organisationReference), paymentMethod);
            },
            addStaff: function (paymentMethodReference, staff) {
                return $http.post(urls.addStaff(paymentMethodReference), staff)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            removeStaff: function (paymentMethodReference, staff) {
                var staffReferences = _.map(staff, 'accountReference');
                return $http.post(urls.removeStaff(paymentMethodReference), staffReferences)
                    .then(function (resp) {
                        return resp.data.removed;
                    });
            },

            removeOneStaff: function (paymentMethodReference, staff) {
                return $http.post(urls.removeStaff(paymentMethodReference), [staff.accountReference])
                    .then(function (resp) {
                        return resp.data.removed[0];
                    });
            },
            removePaymentMethod: function (organisationReference, paymentMethodReference) {
                return $http.post(urls.removePaymentMethod(organisationReference, paymentMethodReference))
                    .then(function (resp) {
                        alert("Payment method reference " + paymentMethodReference + " has been removed");
                        return;
                    });
            },
            initPaymentMethodNotificationTypeOptions: function (notificationType) {
                var notifyOrganisation = true;
                var ccPassenger = false;
                switch (notificationType) {
                    case 'ORGANISATION_EMAIL':
                        notifyOrganisation = true;
                        ccPassenger = false;
                        break;
                    case 'PASSENGER_EMAIL':
                        notifyOrganisation = false;
                        ccPassenger = false;
                        break;
                    case 'ORGANISATION_AND_PASSENGER_EMAILS':
                        notifyOrganisation = true;
                        ccPassenger = true;
                        break;
                }

                var notificationTypeOptions = {
                    notifyOrganisation: notifyOrganisation,
                    ccPassenger: ccPassenger
                };
                return notificationTypeOptions;
            },
            buildPaymentMethodNotificationType: function (notifyOrganisation, ccPassenger) {
                var notificationType;

                if (notifyOrganisation) {
                    notificationType = 'ORGANISATION_EMAIL';
                    if (ccPassenger || ccPassenger == 'true') {
                        notificationType = 'ORGANISATION_AND_PASSENGER_EMAILS';
                    }
                }
                else {
                    notificationType = 'PASSENGER_EMAIL';
                }

                return notificationType;
            }
        };

        return api;
    }]);
